import { gql } from '@apollo/client';

export const SEND_CODE = gql`
  mutation sendCode($phoneNumber: String!) {
    contacts {
      checkPhoneWithConfirmationCode(phoneNumber: $phoneNumber) {
        operationId
        nextTryAfterMsecs
      }
    }
  }
`;

export const CONFIRM_PHONE_NUMBER = gql`
  mutation confirmWithCode($operationId: ID!, $code: String!) {
    contacts {
      confirmWithCode(operationId: $operationId, code: $code)
    }
  }
`;

export const SEND_FORM_DATA = gql`
  mutation sendFormData($data: ACC_AccreditationRequestInput!) {
    accreditation {
      accreditation {
        create(data: $data) {
          id
        }
      }
    }
  }
`;
