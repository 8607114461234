import React from 'react';
import styles from './ClubHeader.module.scss';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import { useTranslation } from 'react-i18next';
import { getSupportedLngs, switchLng } from 'i18n';

export default function ClubHeader() {
  const { t, i18n } = useTranslation();

  const supportedLangs = getSupportedLngs();
  const renderLangSwitcher = () => {
    if (supportedLangs.length > 1) {
      return supportedLangs.map((lang) => (
        <div
          className={i18n.language === lang ? styles.header__text_language_active : styles.header__text_language}
          onClick={() => switchLng(lang)}
        >
          {lang.toUpperCase()}
        </div>
      ));
    } else return null;
  };

  return (
    <div className={styles.header}>
      <div className={styles.header_logo}>
        <img src={getRequiredEnv('REACT_APP_LOGO_SRC')} alt="" />
        <div>
          <div>{t('club_header.div')}</div>
          <div>{t('react_app.club_title')}</div>
        </div>
      </div>
      <div className={styles.header__display_container_language_container}>{renderLangSwitcher()}</div>
    </div>
  );
}
