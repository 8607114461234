import React from 'react';
import styles from './ErrorPage.module.scss';
import { t } from 'i18next';

export default function ErrorPage(props: { type: 'error' | 'closed' }) {
  return (
    <div className={styles.page}>
      {props.type === 'error' ? t('error_page.div.data_loading_error') : t('error_page.div.accreditation_closed')}
    </div>
  );
}
