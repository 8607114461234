import { LocalizedString } from 'common/utils/LocalizedString';

export enum FieldType {
  TEXT = 'TEXT',
  LOCALIZED_TEXT = 'LOCALIZED_TEXT',
  NUMERIC = 'NUMERIC',
  DATE_TIME = 'DATE_TIME',
  FILE = 'FILE',
  IMAGE_FILE = 'IMAGE_FILE',
  PHONE = 'PHONE',
  BOOLEAN = 'BOOLEAN',
}

export enum RequestType {
  FOR_MATCH = 'FOR_MATCH',
  FOR_SEASON = 'FOR_SEASON',
  FOR_TOURNAMENT = 'FOR_TOURNAMENT',
}

export type AccessCategoryField = {
  id: string;
  title: LocalizedString;
  description?: LocalizedString;
  hint?: LocalizedString;
  required: boolean;
  type: FieldType;
  appliedToCategoryId?: string;
};

export type AccessCategory = {
  id: string;
  title: LocalizedString;
  fields: AccessCategoryField[];
  hidden: boolean;
};

export type RemoteFile = {
  id: string;
  publicLink: string;
  fileName?: string;
};

export type Team = {
  id: string;
  title: string;
  logo?: RemoteFile;
  location?: string;
};

export type Season = {
  id: string;
  startDate: Date;
  endDate: Date;
};

export type Tournament = {
  id: string;
  title: string;
  logo?: RemoteFile;
};

export type Match = {
  id: string;
  title: LocalizedString;
  description?: LocalizedString;
  startTime?: Date;
  endTime?: Date;
  team1: Team;
  team2: Team;
  season?: Season;
  cover?: RemoteFile;
};

export type AccreditationDescriptor = {
  id: string;
  requestTypes: RequestType[];
  availableMatches: Match[];
  availableTournaments: Tournament[];
  availableSeasons: Season[];
  availableAccessCategories: AccessCategory[];
  customBackgroundColor?: string;
  customBackgroundImageFile?: RemoteFile;
  darknessPercentage?: number;
};

export type Event = Match | Tournament | Season;

export type Events = {
  matches: Match[];
  tournaments: Tournament[];
  seasons: Season[];
};

export type AccreditationInput = {
  name: Object;
  surname: Object;
  patronymic?: Object;
  phone: string;
  email: string;
  photoFileId: string;
  operationId: string;
  matchIds?: string[];
  tournamentIds?: string[];
  seasonIds?: string[];
  requestType: RequestType;
  accessCategoryId: string;
  additionalFields: AdditionalField[];
};

export type AdditionalField = {
  id: string;
  value?: string;
};

export type ConfirmationResult = {
  operationId: string;
  nextTryAfterMsecs: number;
};
