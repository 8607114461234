import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { LocalizedString, localizedStringIsNotEmpty } from 'common/utils/LocalizedString';
import { transliterate } from 'common/utils/stringHelpers';
import generateError from 'common/utils/generateError';

import { LangCode, TransliterationInputProps } from 'common/utils/TransliterationInput.types';
import styles from './TransliterationInput.module.scss';
import { capitalize } from 'lodash';

export default function TransliterationInput({
  title,
  inputName,
  sourceLngCode = 'ru',
  resultLngCode = 'en',
  value,
  restrictionRules,
  labelPosition = 'above',
  direction = 'row',
  justify = 'space-between',
  borderRadius = '2px',
  columns,
  singleLabel,
  onChange,
  transliterationHandler = transliterate,
  wrapperClasses,
}: TransliterationInputProps) {
  const columnSystem = 24;
  const { t } = useTranslation();
  const [input, setInput] = useState<LocalizedString | undefined>(value);
  const wrapperClassNames = cn(
    styles.wrapper,
    styles[`direction${capitalize(direction)}`],
    { [styles[`justify-${justify}`]]: direction === 'row' },
    { [styles[`align-${justify}`]]: direction === 'column' },
    { [styles.singleLabel]: singleLabel },
    wrapperClasses
  );

  const labelClasses = cn(styles.label, styles[`label${capitalize(labelPosition)}`]);

  const calculateColumnsWidth = () => {
    if (typeof columns === 'number') {
      let widths = [];
      for (let i = 0; i < columns; i++) {
        widths.push(Math.floor(100 / columns).toString() + '%');
      }
      return widths;
    } else if (columns?.length) {
      return columns.map((v) => ((v / columnSystem) * 100).toString() + '%');
    } else {
      return undefined;
    }
  };

  const columnWidth = columns ? calculateColumnsWidth() : undefined;

  const calculatedStyle = (elNumber: number) => {
    if (columnWidth) {
      return columnWidth[elNumber] ? { style: { width: columnWidth[elNumber] } } : null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (onChange && input) {
      onChange(localizedStringIsNotEmpty(input) ? input : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleInputChange = (value: string, lang: LangCode) => {
    const currentInput = input?.toObject();
    let newValue: LocalizedString;
    if (lang === sourceLngCode) {
      newValue = LocalizedString.fromObject({ [lang]: value, [resultLngCode]: transliterationHandler(value) });
    } else if (lang === resultLngCode) {
      newValue = LocalizedString.fromObject({ ...currentInput, [lang]: value });
    } else {
      throw new Error('Incorrect language code');
    }
    setInput(newValue);
  };

  const restrictInput = (e: React.KeyboardEvent<HTMLInputElement>, lang: LangCode) => {
    const restrictionRule = restrictionRules?.[lang];
    if (restrictionRule) {
      const re = new RegExp(restrictionRule.expression, restrictionRule.flags);
      if (!re.test(e.key)) {
        e.preventDefault();
        restrictionRule.errorMessage && generateError(restrictionRule.errorMessage);
      }
    }
  };

  return (
    <div className={wrapperClassNames}>
      {singleLabel && (
        <h3 className={styles.itemTitle} {...calculatedStyle(0)}>
          {title}
        </h3>
      )}
      <label className={labelClasses} {...(singleLabel ? calculatedStyle(1) : calculatedStyle(0))}>
        {!singleLabel && <h3 className={styles.itemTitle}>{title}</h3>}
        <Input
          id={`${inputName}-${sourceLngCode}`}
          size="large"
          className={styles.customInputAcc}
          value={input?.toString(sourceLngCode)}
          onChange={(e) => handleInputChange(e.target.value, sourceLngCode)}
          onKeyPress={(e) => restrictInput(e, sourceLngCode)}
          onPaste={(e) => e.preventDefault()}
          maxLength={80}
          autoComplete={inputName}
          spellCheck={false}
          style={{ borderRadius }}
        />
      </label>
      <label className={labelClasses} {...(singleLabel ? calculatedStyle(2) : calculatedStyle(1))}>
        {!singleLabel && <h3 className={styles.itemTitle}>{`${title} ${t('localized_text_input.label')}`}</h3>}
        <Input
          id={`${inputName}-${resultLngCode}`}
          size="large"
          className={styles.customInputAcc}
          value={input?.toString(resultLngCode)}
          onChange={(e) => handleInputChange(e.target.value, resultLngCode)}
          onKeyPress={(e) => restrictInput(e, resultLngCode)}
          onPaste={(e) => e.preventDefault()}
          maxLength={80}
          autoComplete="nope"
          spellCheck={false}
          style={{ borderRadius }}
        />
      </label>
    </div>
  );
}
