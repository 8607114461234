import React from 'react';
import { AccessCategoryField, FieldType } from 'form/graphql/model';
import SingleFileUpload from './SingleFileUpload';
import ImageUpload from './ImageUpload';
import { Input, Form, Col } from 'antd';
import LocalizedTextInput from './LocalizedTextInput';
import { useTranslation } from 'react-i18next';

export default function AdditionalFields(props: { fields: AccessCategoryField[] }) {
  const { t } = useTranslation();
  return (
    <>
      {props.fields.map((field) => (
        <Form.Item
          key={field.id}
          rules={[
            {
              required: field.required,
              message: `${t('additional_fields.form_item.rules.message')} ${field.title.toString().toLowerCase()}`,
            },
          ]}
          name={`additional_${field.id}`}
        >
          {(() => {
            switch (field.type) {
              case FieldType.FILE:
                return (
                  <SingleFileUpload
                    title={field.title.toString()}
                    maxFileSize={15}
                    accept={['.doc', '.docx', '.pdf', '.png', '.jpeg', '.jpg']}
                  />
                );
              case FieldType.IMAGE_FILE:
                return <ImageUpload />;
              case FieldType.TEXT:
                return <AdditionalInput />;
              case FieldType.LOCALIZED_TEXT:
                return <LocalizedTextInput allowNumbers title={field.title.toString()} />;
              default:
                return null;
            }
          })()}
        </Form.Item>
      ))}
    </>
  );
}

function AdditionalInput(props: { value?: string; onChange?: (value: string) => void }) {
  return (
    <Col lg={11} sm={24} xs={24}>
      <Input
        maxLength={160}
        onChange={(e) => {
          if (props.onChange) props.onChange(e.target.value);
        }}
      />
    </Col>
  );
}
