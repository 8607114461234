import React, { useState } from 'react';
import { Upload, message, Button, Col, ConfigProvider } from 'antd';
import upload from '../assets/upload.svg';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { extensionToFileType } from 'common/utils/stringHelpers';
import { useTranslation } from 'react-i18next';

export default function SingleFileUpload(props: {
  title: string;
  onChange?: (fileId?: string) => void;
  value?: string;
  imageUpload?: boolean;
  accept: string[];
  maxFileSize: number;
}) {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const { t } = useTranslation();
  function handleUpload(info: UploadChangeParam) {
    switch (info.file.status) {
      case 'error': {
        break;
      }
      case 'done': {
        const fileList = info.fileList.map((file) => {
          if (file.response) {
            const id: string = file.response.fileIds[0];
            debugger;
            if (props.onChange) props.onChange(id);
          }
          return file;
        });
        setFileList(fileList);
        break;
      }
      case 'removed': {
        setFileList([]);
        if (props.onChange) props.onChange(undefined);
        break;
      }
      default: {
        setFileList(info.fileList);
        break;
      }
    }
  }

  function beforeUpload(file: UploadFile, fileList: UploadFile[]): boolean {
    function generateError(msg: string) {
      message.error(msg);
      setFileList(
        fileList.map((file) => {
          file.status = 'error';
          return file;
        })
      );
    }

    const supportedFyleTypes = props.accept.map((e) => extensionToFileType(e));
    if (file.type === undefined || file.size === undefined) return false;
    if (!supportedFyleTypes.includes(file.type)) {
      generateError(`${t('single_file_upload.generate_error.only_format_files')} ${props.accept.join(' ')}`);
      return false;
    }
    const tooBig = file.size / 1024 / 1024 > props.maxFileSize;
    if (tooBig) {
      generateError(`${t('single_file_upload.generate_error.images_up')} ${props.maxFileSize}MB`);
      return false;
    }

    return true;
  }

  return (
    <Col lg={props.imageUpload ? 24 : 11} sm={24} xs={24}>
      <Upload
        action={getRequiredEnv('REACT_APP_FILE_UPLOAD_URL')}
        onChange={handleUpload}
        fileList={fileList}
        beforeUpload={beforeUpload}
        accept={props.accept.join(', ')}
      >
        {fileList.length >= 1 ? null : (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgb(72, 133, 90)',
              },
            }}
          >
            <Button id="SingleFileUploadPropsTitleButton">
              <img src={upload} alt={t('single_file_upload.img.alt')} />
              {props.title}
            </Button>
          </ConfigProvider>
        )}
      </Upload>
    </Col>
  );
}
