import React from 'react';
import InputMask from 'react-input-mask';

export default function PhoneInput(props: { value?: any; onChange?: () => void }) {
  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      placeholder={'+7'}
      value={props.value}
      onChange={props.onChange}
      className="ant-input"
      style={{ height: '40px', color: '#000000', outline: 'none', width: '100%' }}
    />
  );
}
