import { t } from "i18next";

export const ERROR_MESSAGE_TIME_DURATION = 5;
export const ERROR_MESSAGE_STYLE = { maxWidth: '300px', margin: '0 auto' };

export const COMMON_REGEXP = {
  CYRYLLIC_LETTERS_AND_SYMBOLS: `^[а-яёА-ЯЁ0-9 ,."«»№()\\-]+$`,
  LATIN_LETTERS_AND_SYMBOLS: `^[a-zA-Z0-9 ,."«»№()\\-]+$`,
};

export const ERROR_MESSAGE_CONTENT = {
  languageError: t('constants'),
};


