import React, { useState } from 'react';
import './App.scss';
import AccreditationForm from 'form/AccreditationForm';
import ErrorPage from 'common/ErrorPage';
import { deserialize } from '@sebbia/deserializer';
import { deserializeAccreditationRequestDescriptor } from 'form/graphql/deserializers';
import Loader from 'common/Loader';
import { AccreditationDescriptor } from 'form/graphql/model';
import { useQuery } from '@apollo/client';
import { GET_ACCREDITATION_REQUEST_DESCRIPTOR } from 'form/graphql/queries';
import appError from 'common/utils/appError';
import { initStyles } from 'common/utils/stringHelpers';

function App() {
  const { loading, error, data } = useQuery(GET_ACCREDITATION_REQUEST_DESCRIPTOR, {
    onCompleted: (data) => {
      try {
        const result = deserialize(data, (d) =>
          d
            .optional('accreditation.accreditationRequestDescriptor.getActive')
            ?.asObject((o) => deserializeAccreditationRequestDescriptor(o))
        );
        setDescriptor(result);
      } catch (e: any) {
        appError(e);
      }
    },
  });

  const [descriptor, setDescriptor] = useState<AccreditationDescriptor>();
  const { className, customStyle } = { ...initStyles(descriptor) };

  if (error) return <ErrorPage type="error" />;
  if (!error && !data && !loading) return <ErrorPage type="closed" />;

  return (
    <div className={className} style={customStyle}>
      {loading ? <Loader /> : descriptor && <AccreditationForm descriptor={descriptor} />}
    </div>
  );
}

export default App;
