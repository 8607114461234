import React, { useState } from 'react';
import { Form, Col, Row, Select } from 'antd';
import { AccessCategory, RequestType, Events } from 'form/graphql/model';
import AdditionalFields from './AdditionalFields';
import AccreditationTypeSelector from './AccreditationTypeSelector';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export default function WorkingData(props: {
  accessCategories: AccessCategory[];
  requestTypes: RequestType[];
  events: Events;
  form: FormInstance<any>;
}) {
  const [currentCaegory, setCurrentCategory] = useState<AccessCategory>();
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col lg={11} sm={24} xs={24}>
          <Form.Item
            label={t('working_data.form_item.label.access_category')}
            name="accessCategory"
            rules={[{ required: true, message: t('working_data.form_item.rules.message.choose_category') }]}
          >
            <Select
              placeholder={t('working_data.select.placeholder.select_category')}
              onChange={(value) =>
                setCurrentCategory(props.accessCategories.find((a) => a.id === value) as AccessCategory)
              }
            >
              {props.accessCategories &&
                props.accessCategories.map((category) => (
                  <Select.Option value={category.id} key={category.id}>
                    {category.title.toString()}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {currentCaegory && <AdditionalFields fields={currentCaegory.fields} />}
      <AccreditationTypeSelector requestTypes={props.requestTypes} events={props.events} form={props.form} />
    </>
  );
}
