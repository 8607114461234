import { gql } from '@apollo/client';

export const GET_ACCREDITATION_REQUEST_DESCRIPTOR = gql`
  query getAccreditationDescriptor {
    accreditation {
      accreditationRequestDescriptor {
        getActive {
          id
          requestTypes
          availableAccessCategories {
            id
            title
            fields {
              id
              hint
              description
              title
              required
              type
            }
            hidden
          }
          allIncludedMatches {
            id
            title
            startTime
            team1 {
              id
              title
              logo {
                id
                publicLink
              }
            }
            team2 {
              id
              title
              logo {
                id
                publicLink
              }
            }
          }
          availableSeasons {
            id
            startDate
            endDate
          }
          customBackgroundColor
          darknessPercentage
          customBackgroundImageFile {
            id
            publicLink
          }
          availableTournaments {
            id
            title
            logo {
              id
              publicLink
            }
          }
        }
      }
    }
  }
`;
