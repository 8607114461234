import { getSupportedLngs } from 'i18n';
import i18next from 'i18next';

type KeyValueObject = Record<string, string>;

const fallbackLangCodes = i18next.options.fallbackLng as string[];
const fallbackLangCode = fallbackLangCodes[0];

/**
 * String with multiple localizations
 */
export class LocalizedString {
  private constructor(private readonly data: KeyValueObject) {}

  /**
   * Create from object {string: string}.
   * @param data Key - language, value - string value for the specified language.
   */
  static fromObject(data: any): LocalizedString {
    const d: KeyValueObject = {};

    if (typeof data === 'string') {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
        data = parsedData;
        this.getStringByKey(data, d);
      } catch (error) {
        d[fallbackLangCode] = data;
      }
    } else {
      this.getStringByKey(data, d);
    }
    return new LocalizedString(d);
  }

  private static getStringByKey(data: any, d: KeyValueObject) {
    if (typeof data === 'object' && Object.keys(data).length) {
      for (const key in data) {
        const value = data[key];
        if (typeof value !== 'string') {
          throw new Error('<eecdb6c6> Data is not a string');
        }
        d[key] = value;
      }
    } else {
      d[fallbackLangCode] = '';
    }
  }

  toObject(): KeyValueObject {
    return this.data;
  }

  hasLanguage(langCode: string): boolean {
    return this.data.hasOwnProperty(langCode);
  }

  static fromString(s: string, langCode?: string): LocalizedString {
    return new LocalizedString({
      [langCode || fallbackLangCode]: s,
    });
  }

  toString(langCode?: string): string {
    let result;
    if (langCode) {
      result = this.data[langCode];
      if (result || result === '') {
        return result;
      }
    }
    result = this.data[fallbackLangCode];
    if (result || result === '') {
      return result;
    }
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        result = this.data[key];
        return result || '';
      }
    }
    throw new Error(`<dce73154> Cannot get localized string for lang code: ${langCode}`);
  }
}

export function localizedStringIsNotEmpty(localizedString: LocalizedString) {
  const supportedLangs = getSupportedLngs();
  let isNotEmpty = false;
  supportedLangs.forEach((lng) => {
    if (localizedString.toString(lng) !== '') {
      isNotEmpty = true;
    }
  });
  return isNotEmpty;
}
