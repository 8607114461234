import React, { useState, useEffect } from 'react';
import styles from './ContactData.module.scss';
import { Form, Input, Row, Col, message, Button, ConfigProvider } from 'antd';
import PhoneInput from './PhoneInput';
import { useMutation } from '@apollo/client';
import { SEND_CODE } from 'form/graphql/mutations';
import { FormInstance } from 'antd/lib/form';
import { deserialize } from '@sebbia/deserializer';
import appError from 'common/utils/appError';
import { ConfirmationResult } from 'form/graphql/model';
import { deserializeSmsConfirmation } from 'form/graphql/deserializers';
import { useTranslation } from 'react-i18next';

export default function ContactData(props: {
  form: FormInstance<any>;
  setOperationId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const [timeLeft, setTimeLeft] = useState<number>();
  const { t } = useTranslation();
  const [sendCode, { loading }] = useMutation(SEND_CODE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      try {
        const res: ConfirmationResult = deserialize(data, (d) =>
          d.required('contacts.checkPhoneWithConfirmationCode').asObject((o) => deserializeSmsConfirmation(o))
        );
        props.setOperationId(res.operationId);
        setTimeLeft(res.nextTryAfterMsecs / 1000);
      } catch (error: any) {
        appError({ error, message: t('contact_data.app_error.message') });
      }
    },
  });

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <>
      <Row justify="space-between">
        <Col lg={11} sm={24} xs={24}>
          <Form.Item
            label={t('contact_data.form_item.label.mobile_number')}
            name="phone"
            rules={[
              {
                required: true,
                message: t('contact_data.form_item.rules.message.phone_number'),
                pattern: /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}/,
                validateTrigger: 'onBlur',
              },
            ]}
          >
            <PhoneInput />
          </Form.Item>
        </Col>
        <Col lg={6} sm={24} xs={24}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgb(72, 133, 90)',
              },
            }}
          >
            <Button
              id="ContactDataConfirmNumberButton"
              className={styles.smsBtn}
              loading={loading}
              disabled={!!timeLeft}
              onClick={async () => {
                let fields;
                try {
                  fields = await props.form.validateFields(['phone']);
                } catch (error) {
                  console.warn(error);
                }
                if (!fields) return;
                try {
                  if (fields.phone) await sendCode({ variables: { phoneNumber: fields.phone } });
                  message.success(`${t('message_success.sms_successfully_sent')} ${fields.phone}`);
                } catch (error: any) {
                  appError({ error });
                }
              }}
            >
              {!timeLeft ? t('contact_data.button.confirm_number') : `${timeLeft} ${t('contact_data.button.sec')}`}
            </Button>
          </ConfigProvider>
        </Col>
        <Col lg={6} sm={24} xs={24}>
          <Form.Item
            label={t('contact_data.form_item.label.sms_code')}
            name="code"
            rules={[{ required: true, message: t('contact_data.form_item.rules.message.code_from_sms') }]}
          >
            <Input
              onKeyPress={(e) => {
                if (/[^\d]/.test(e.key)) e.preventDefault();
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={11} sm={24} xs={24}>
          <Form.Item
            label={t('contact_data.form_item.label.email')}
            name="email"
            rules={[{ required: true, type: 'email', message: t('contact_data.form_item.rules.message.email') }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('contact_data.form_item.label.comment')} name="comment">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'rgb(72, 133, 90)',
            },
          }}
        >
          <Input.TextArea rows={5} />
        </ConfigProvider>
      </Form.Item>
    </>
  );
}
