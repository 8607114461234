import React from 'react';
import { Form } from 'antd';
import LocalizedTextInput from './LocalizedTextInput';
import ImageUpload from './ImageUpload';
import { useTranslation } from 'react-i18next';
import TransliterationInput from './TransliterationInput';

export default function PersonalData() {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        name="surname"
        rules={[{ required: true, message: t('personal_data.form_item.rules.message.enter_last_name') }]}
      >
        <TransliterationInput title={t('personal_data.localized_text_input.title.last_name')} inputName={'surname'}/>
      </Form.Item>
      <Form.Item
        name="name"
        rules={[{ required: true, message: t('personal_data.form_item.rules.message.enter_name') }]}
      >
        <TransliterationInput title={t('personal_data.localized_text_input.title.name')} inputName={'name'}/>
      </Form.Item>
      <Form.Item name="patronymic" rules={[{ required: false }]}>
        <TransliterationInput title={t('personal_data.localized_text_input.title.surname')} inputName={'patronymic'}/>
      </Form.Item>
      <Form.Item
        name="photo"
        rules={[{ required: true, message: t('personal_data.form_item.rules.message.upload_photo') }]}
      >
        <ImageUpload />
      </Form.Item>
    </>
  );
}
