import React, { useState, useEffect } from 'react';
import { Form, Radio, Select, Col, ConfigProvider } from 'antd';
import styles from './AccreditationTypeSelector.module.scss';
import { RequestType, Events, Match, Tournament, Season, Event } from 'form/graphql/model';
import { requestTypeToString, requestTypeToSelectorString } from 'common/utils/stringHelpers';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

export default function AccreditationTypeSelector(props: {
  requestTypes: RequestType[];
  events: Events;
  form: FormInstance<any>;
}) {
  const [eventType, setEventType] = useState<RequestType>(props.requestTypes[0]);
  const { t } = useTranslation();

  const getEvents = (type: RequestType) => {
    switch (type) {
      case RequestType.FOR_MATCH:
        return props.events.matches;
      case RequestType.FOR_SEASON:
        return props.events.seasons;
      case RequestType.FOR_TOURNAMENT:
        return props.events.tournaments;
    }
  };

  useEffect(() => {
    props.form.resetFields(['ids']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  return (
    <>
      <Col lg={11} sm={24}>
        <Form.Item
          label={t('accreditation_type_selector.form_item.label')}
          name="requestType"
          style={{ marginBottom: '10px' }}
          initialValue={eventType}
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgb(45, 119, 70)',
              },
            }}
          >
            <Radio.Group className={styles.radio} onChange={(e) => setEventType(e.target.value)}>
              {props.requestTypes &&
                props.requestTypes.map((type, index) => (
                  <Radio value={type} key={index}>
                    {requestTypeToString(type)}
                  </Radio>
                ))}
            </Radio.Group>
          </ConfigProvider>
        </Form.Item>
        <Form.Item
          name="ids"
          rules={[
            {
              required: true,
              message: `${t('accreditation_type_selector.form_item.rules.message')} ${requestTypeToSelectorString(
                eventType
              )}`,
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Selector events={getEvents(eventType)} eventType={eventType} />
        </Form.Item>
      </Col>
    </>
  );
}

const Selector = (props: {
  events: Array<Event>;
  eventType: RequestType;
  value?: string;
  onChange?: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const placeholderText = `${t('accreditation_type_selector.selector.placeholder_text')} ${requestTypeToSelectorString(
    props.eventType
  )}`;
  const [event, setEvent] = useState<Event>();
  return (
    <>
      <Select
        placeholder={placeholderText}
        optionLabelProp="label"
        onChange={(value) => props.onChange && props.onChange(value as string)}
        onSelect={(value, option) => setEvent(option.event)}
      >
        {props.events.map((event) => (
          <Select.Option value={event.id} key={event.id} label={placeholderText} event={event}>
            {renderOption(event)}
          </Select.Option>
        ))}
      </Select>
      {event && <div className={styles.selected}>{renderOption(event)}</div>}
    </>
  );
};

const MatchOption = (props: { match: Match | undefined }) => (
  <>
    {props.match && (
      <div className={styles.match}>
        <div>
          <div>{`${props.match.team1.title} vs ${props.match.team2.title},`}</div>
          {props.match.startTime && (
            <div>
              {props.match.startTime.toLocaleDateString('en', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          )}
        </div>
        <img src={props.match.team2.logo?.publicLink} alt={props.match.team2.title} />
      </div>
    )}
  </>
);

const SeasonOption = (props: { season: Season | undefined }) => {
  const { t } = useTranslation();
  return (
    <>
      {props.season &&
        `${t(
          'accreditation_type_selector.season_option'
        )} ${props.season.startDate.getFullYear()}/${props.season.endDate.getFullYear()}`}
    </>
  );
};

const TournamentOption = (props: { tournament: Tournament | undefined }) => (
  <>
    {props.tournament && (
      <div className={styles.tournament}>
        {props.tournament.logo && <img src={props.tournament.logo.publicLink} alt={props.tournament.title} />}
        {props.tournament.title}
      </div>
    )}
  </>
);

const renderOption = (event: Event) => {
  if ('team1' in event) return <MatchOption match={event as Match} />;
  if ('startDate' in event) return <SeasonOption season={event as Season} />;
  return <TournamentOption tournament={event as Tournament} />;
};
