import React, { useState } from 'react';
import styles from './AccreditationForm.module.scss';
import { Button, ConfigProvider, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PersonalData from './components/PersonalData';
import WorkingData from './components/WorkingData';
import ContactData from './components/ContactData';
import { useMutation } from '@apollo/client';
import { AccreditationDescriptor, AccreditationInput, RequestType } from './graphql/model';
import { CONFIRM_PHONE_NUMBER, SEND_FORM_DATA } from './graphql/mutations';
import { parseAdditionalFields } from 'common/utils/stringHelpers';
import { LocalizedString } from 'common/utils/LocalizedString';
import appError from 'common/utils/appError';
import ClubHeader from './components/ClubHeader';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import { useTranslation } from 'react-i18next';

export default function AccreditationForm(props: { descriptor: AccreditationDescriptor }) {
  const { descriptor } = { ...props };
  const [form] = useForm();
  const [confirmPhoneNumber, { data: confirmed }] = useMutation<any, { operationId: string; code: string }>(
    CONFIRM_PHONE_NUMBER,
    {
      onCompleted: (data) => data?.contacts?.confirmWithCode || false,
    }
  );
  const [sendData] = useMutation<any, { data: AccreditationInput }>(SEND_FORM_DATA);
  const [submitting, setSubmitting] = useState(false);
  const [operationId, setOperationId] = useState<string>();
  const { t } = useTranslation();
  const submit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      const fields = await form.validateFields();
      if (!operationId) throw new Error(t('accreditation_form.error.error_sending_sms'));
      if (!confirmed) {
        const confirmation =
          fields.code && (await confirmPhoneNumber({ variables: { operationId, code: fields.code } }));
        if (!confirmation) throw new Error(t('accreditation_form.error.number_not_been_confirmed'));
      }
      const additionalFields = parseAdditionalFields(fields);
      if (!additionalFields) throw new Error(t('accreditation_form.error.input_error'));

      let formData: AccreditationInput = {
        name: (fields.name as LocalizedString).toObject(),
        surname: (fields.surname as LocalizedString).toObject(),
        patronymic: fields?.patronymic ? (fields.patronymic as LocalizedString).toObject() : undefined,
        phone: fields.phone.match(/\d/g).join(''),
        email: fields.email.trim(),
        photoFileId: fields.photo,
        operationId: operationId,
        requestType: fields.requestType,
        accessCategoryId: fields.accessCategory,
        additionalFields,
      };
      switch (fields.requestType) {
        case RequestType.FOR_MATCH:
          formData.matchIds = [fields.ids];
          break;
        case RequestType.FOR_SEASON:
          formData.seasonIds = [fields.ids];
          break;
        case RequestType.FOR_TOURNAMENT:
          formData.tournamentIds = [fields.ids];
          break;
      }

      await sendData({ variables: { data: formData } });
      message.success(t('message_success.application_accreditation_successfully_sent'));
      form.resetFields();
      setOperationId(undefined);
    } catch (error: any) {
      appError({ error });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form className={styles.form} form={form} layout="vertical" size="large" onFinish={submit} requiredMark={false}>
      <ClubHeader />
      <h1>{t('accreditation_form.h1')}</h1>
      <h2>{t('accreditation_form.h2.personal_information')}</h2>
      <PersonalData />
      <h2>{t('accreditation_form.h2.working_data')}</h2>
      <WorkingData
        accessCategories={descriptor.availableAccessCategories}
        requestTypes={descriptor.requestTypes}
        form={form}
        events={{
          matches: descriptor.availableMatches,
          seasons: descriptor.availableSeasons,
          tournaments: descriptor.availableTournaments,
        }}
      />
      <h2>{t('accreditation_form.h2.contact_details')}</h2>
      <ContactData form={form} setOperationId={setOperationId} />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'rgb(45, 119, 70)',
          },
        }}
      >
        <Button id="AccreditationFormSubmitApplicationButton" htmlType="submit" type="primary" className={styles.submitBtn} loading={submitting}>
          {t('accreditation_form.button')}
        </Button>
      </ConfigProvider>
      <div className={styles.link}>
        {t('accreditation_form.div.0')}{' '}
        <a href={getRequiredEnv('REACT_APP_POLICY_LINK')}>{t('accreditation_form.div.1')}</a>
      </div>
    </Form>
  );
}
