import { LocalizedString } from './LocalizedString';
import { RequestType, AdditionalField, AccreditationDescriptor } from 'form/graphql/model';
import { Store } from 'antd/lib/form/interface';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import { t } from 'i18next';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

const cyrillicToTranslit = new CyrillicToTranslit();

export const transliterate = (string: string) => {
  const strTrans = cyrillicToTranslit.transform(string);
  const res = strTrans.charAt(0).toUpperCase() + strTrans.slice(1);
  if (strTrans.length === 0) {
    return strTrans;
  } else {
    return res;
  }
};

export function localizedStringIsNotEmpty(localizedString: LocalizedString): boolean {
  return localizedString.toString('ru') !== '' && localizedString.toString('en') !== '';
}

export function extensionToFileType(extension: string) {
  const dict: Record<string, string> = {
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.svg': 'image/svg+xml',
    '.pdf': 'application/pdf',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };
  return dict[extension];
}

export function enumFromString<T>(type: T, str: string): T[keyof T] {
  const casted = str as keyof T;
  const result = type[casted];
  if (result === undefined) {
    throw new Error(`Unregistered enum value: ${str}`);
  }
  return result;
}

export function requestTypeToSelectorString(type: RequestType) {
  switch (type) {
    case RequestType.FOR_MATCH:
      return t('string_helpers.request_type_to_selector_string.case.match');
    case RequestType.FOR_SEASON:
      return t('string_helpers.request_type_to_selector_string.case.season');
    case RequestType.FOR_TOURNAMENT:
      return t('string_helpers.request_type_to_selector_string.case.tournament');
  }
}

export function requestTypeToString(type: RequestType) {
  switch (type) {
    case RequestType.FOR_MATCH:
      return t('string_helpers.request_type_to_string.case.one-time');
    case RequestType.FOR_SEASON:
      return t('string_helpers.request_type_to_string.case.seasonal');
    case RequestType.FOR_TOURNAMENT:
      return t('string_helpers.request_type_to_string.case.to_tournament');
  }
}

export function parseAdditionalFields(fields: Store): AdditionalField[] | undefined {
  const keys = Object.keys(fields);
  const additionalFields = keys.filter((key) => key.includes('additional'));
  const res: AdditionalField[] | undefined = additionalFields?.map((fieldName) => ({
    id: fieldName.split('_')[1],
    value:
      fields[fieldName] instanceof LocalizedString
        ? JSON.stringify((fields[fieldName] as LocalizedString).toObject())
        : fields[fieldName],
  }));
  return res;
}

export function parseError(error: Error): string {
  const defaultError = t('string_helpers.parse_error.default_error');
  const errorString = error.message && error.message.match(/uid=(.*?),/);
  if (!errorString && !error.message) return defaultError;
  if (!errorString && error.message) return error.message;

  switch (errorString![1]) {
    case '9b56ea08':
      return t('string_helpers.parse_error.case.sms_sending_error');
    case '978cef38':
      return t('string_helpers.parse_error.case.failed_verify_phone');
    case '6e48c76c':
      return t('string_helpers.parse_error.case.invalid_phone_verification');
    case '034a8d14':
      return t('string_helpers.parse_error.case.phone_not_verified');
    default:
      return defaultError;
  }
}

export function getContrast(color: string) {
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'light' : 'dark';
}

export function initStyles(descriptor?: AccreditationDescriptor) {
  (window as any).less.modifyVars({ '@primary-color': getRequiredEnv('REACT_APP_PRIMARY_COLOR') });
  const customStyle: React.CSSProperties = {
    backgroundColor: descriptor?.customBackgroundColor || getRequiredEnv('REACT_APP_DEFAULT_BG'),
    backgroundImage: `url(${descriptor?.customBackgroundImageFile?.publicLink})`,
  };

  if (descriptor?.darknessPercentage)
    customStyle.background = `linear-gradient(rgba(0,0,0,${descriptor.darknessPercentage}), rgba(0,0,0,${descriptor.darknessPercentage})), url(${descriptor.customBackgroundImageFile?.publicLink})`;

  const className: string =
    getContrast(customStyle.backgroundColor as string) === 'light' && !customStyle.backgroundImage
      ? 'AppDefault'
      : 'AppDefault AppLight';

  return { customStyle, className };
}
