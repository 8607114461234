import React, { useState, useEffect } from 'react';
import styles from './ImageUpload.module.scss';
import SingleFileUpload from './SingleFileUpload';
import avatar_placeholder from '../assets/avatar_placeholder.svg';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import { useTranslation } from 'react-i18next';

export default function ImageUpload(props: { onChange?: (fileId?: string) => void; value?: string }) {
  const [avatar, setAvatar] = useState(avatar_placeholder);
  const { t } = useTranslation();

  useEffect(() => {
    setAvatar(props.value ? `${getRequiredEnv('REACT_APP_FILES_URL')}/${props.value}` : avatar_placeholder);
  }, [props.value]);

  return (
    <div className={styles.upload}>
      <img src={avatar} alt={t('image_upload.img.alt')} />
      <div>
        <SingleFileUpload
          title={t('image_upload.single_file_upload.title')}
          onChange={props.onChange}
          value={props.value}
          accept={['.jpg', '.png', '.jpeg']}
          maxFileSize={15}
          imageUpload
        />
        100KB - 15MB; <br /> 100x100 - 5000x5000.
      </div>
    </div>
  );
}
