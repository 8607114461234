import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { getRequiredEnv } from '@sebbia/dynamic-env';
import './i18n';
import { t } from 'i18next';
import { ConfigProvider } from 'antd';

const client = new ApolloClient({
  uri: getRequiredEnv('REACT_APP_GRAPHQL_ENDPOINT'),
  cache: new InMemoryCache(),
});

var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = getRequiredEnv('REACT_APP_FAVICON');
document.getElementsByTagName('head')[0].appendChild(link);
document.title = t('react_app.title');

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,
        },
      }}
    >
      <App />
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
