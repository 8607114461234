import React, { useState, useEffect } from 'react';
import { Row, Col, Input, message } from 'antd';
import styles from './LocalizedTextInput.module.scss';
import { LocalizedString } from 'common/utils/LocalizedString';
import { Rule } from 'antd/lib/form';
import { transliterate, localizedStringIsNotEmpty } from 'common/utils/stringHelpers';
import { useTranslation } from 'react-i18next';

export default function LocalizedTextInput(props: {
  title: string;
  rules?: Rule[] | undefined;
  onChange?: (value?: LocalizedString) => void;
  value?: LocalizedString;
  allowNumbers?: boolean;
}) {
  const [input, setInput] = useState<LocalizedString>();
  const { t } = useTranslation();
  const handleInputChange = (value: string, locale: 'ru' | 'en') =>
    setInput(
      LocalizedString.fromObject(
        locale === 'ru' ? { ru: value, en: transliterate(value) } : { ...input?.toObject(), en: value }
      )
    );

  useEffect(() => {
    if (props.onChange && input) props.onChange(localizedStringIsNotEmpty(input) ? input : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return (
    <Row justify="space-between">
      <Col lg={11} sm={24} xs={24}>
        <label>{props.title}</label>
        <Input
          onChange={(e) => handleInputChange(e.target.value, 'ru')}
          className={styles.cyrillicInput}
          maxLength={80}
        />
      </Col>
      <Col lg={11} sm={24} xs={24}>
        <label>{`${props.title} ${t('localized_text_input.label')}`}</label>
        <Input
          onChange={(e) => e.preventDefault()}
          value={input?.toString('en')}
          onKeyPress={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          maxLength={80}
          autoComplete="nope"
        />
      </Col>
    </Row>
  );
}
