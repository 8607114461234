import { ObjectDeserializator } from '@sebbia/deserializer';
import {
  AccessCategory,
  AccessCategoryField,
  FieldType,
  Match,
  Team,
  RemoteFile,
  Season,
  AccreditationDescriptor,
  RequestType,
  Tournament,
  ConfirmationResult,
} from './model';
import { LocalizedString } from 'common/utils/LocalizedString';
import { enumFromString } from 'common/utils/stringHelpers';

function deserializeField(o: ObjectDeserializator): AccessCategoryField {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((o) => LocalizedString.fromObject(o)),
    description: o.optional('description')?.as((o) => LocalizedString.fromObject(o)),
    hint: o.optional('hint')?.as((o) => LocalizedString.fromObject(o)),
    required: o.required('required').asBool,
    type: o.required('type').asEnum(FieldType),
    appliedToCategoryId: o.optional('appliedToCategoryId')?.asString,
  };
}

export function deserializeAccessCategory(o: ObjectDeserializator): AccessCategory {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((o) => LocalizedString.fromObject(o)),
    fields: o.required('fields').asArrayOfObjects((o) => deserializeField(o)),
    hidden: o.required('hidden').asBool,
  };
}

function deserializeRemoteFile(o: ObjectDeserializator): RemoteFile {
  return {
    id: o.required('id').asString,
    publicLink: o.required('publicLink').asString,
    fileName: o.optional('fileName')?.asString,
  };
}

function deserializeTeam(o: ObjectDeserializator): Team {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    location: o.optional('location')?.asString,
    logo: o.optional('logo')?.asObject((o) => deserializeRemoteFile(o)),
  };
}

function deserializeSeason(o: ObjectDeserializator): Season {
  return {
    id: o.required('id').asString,
    startDate: o.required('startDate').asDate,
    endDate: o.required('endDate').asDate,
  };
}

function deserializeMatch(o: ObjectDeserializator): Match {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((o) => LocalizedString.fromObject(o)),
    endTime: o.optional('endTime')?.asDate,
    startTime: o.optional('startTime')?.asDate,
    season: o.optional('season')?.asObject((o) => deserializeSeason(o)),
    team1: o.required('team1').asObject((o) => deserializeTeam(o)),
    team2: o.required('team2').asObject((o) => deserializeTeam(o)),
    cover: o.optional('cover')?.asObject((o) => deserializeRemoteFile(o)),
  };
}

function deserializeTournament(o: ObjectDeserializator): Tournament {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    logo: o.optional('logo')?.asObject((o) => deserializeRemoteFile(o)),
  };
}

export function deserializeAccreditationRequestDescriptor(o: ObjectDeserializator): AccreditationDescriptor {
  return {
    id: o.required('id').asString,
    requestTypes: o.required('requestTypes').asArray((r) => enumFromString(RequestType, r)),
    availableMatches: o.required('allIncludedMatches').asArrayOfObjects((o) => deserializeMatch(o)),
    availableSeasons: o.required('availableSeasons').asArrayOfObjects((o) => deserializeSeason(o)),
    availableTournaments: o.required('availableTournaments').asArrayOfObjects((o) => deserializeTournament(o)),
    availableAccessCategories: o
      .required('availableAccessCategories')
      .asArrayOfObjects((o) => deserializeAccessCategory(o)),
    customBackgroundImageFile: o.optional('customBackgroundImageFile')?.asObject((o) => deserializeRemoteFile(o)),
    customBackgroundColor: o.optional('customBackgroundColor')?.asString,
    darknessPercentage: o.optional('darknessPercentage')?.asNumber,
  };
}

export function deserializeSmsConfirmation(o: ObjectDeserializator): ConfirmationResult {
  return {
    operationId: o.required('operationId').asString,
    nextTryAfterMsecs: o.required('nextTryAfterMsecs').asNumber,
  };
}
