import { parseError } from './stringHelpers';
import { message as notify } from 'antd';

type AppError = {
  error?: Error;
  message?: string;
};

export default function appError(args: AppError) {
  const { error, message } = { ...args };
  if (error) console.error(error);
  if (message) notify.error(message);
  if (!message && error) notify.error(parseError(error));
}
